import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { firstValueFrom, of } from 'rxjs';
import { catchError, delay, first, retry, timeout } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private router: Router,
    public http: HttpClient,
    public ngFireAuth: AngularFireAuth
  ) {}

  // Método para obtener el token del almacenamiento local
  getToken(): string | null {
    // ESTO LO HE COMENTADO PARA PODER ENTRAR EN EL LOGIN HASTA QUE PIDAMOS BIEN EL TOKEN
    return localStorage.getItem('authToken');
  }

  // Método para verificar si el token ha caducado
  isTokenExpired(token: string): boolean {
    return false;
    // ESTO LO HE COMENTADO PARA PODER ENTRAR EN EL LOGIN HASTA QUE HAGAMOS BIEN LAS COSAS
    // const expirationDate = this.getExpirationDate(token);
    // if (expirationDate) {
    //   return expirationDate < new Date();
    // }
    // return false;
  }

  // Método para obtener la fecha de expiración del token
  getExpirationDate(token: string): Date | null {
    const payload = JSON.parse(atob(token.split('.')[1]));
    if (payload.exp) {
      return new Date(payload.exp * 1000);
    }
    return null;
  }

  // Método para verificar si el usuario está autenticado
  isAuthenticated(): boolean {
    const user = JSON.parse(localStorage.getItem("user"));
    if(user == null) {
      return false;
    } else if (user["email"] == "admin@v2c.com" || user["email"] == "developers@v2c.com" || user["email"] == "support@v2c.com"){
      return false;
    } else {
      return true;
    }
  }

  // Método para redirigir al usuario al login si no está autenticado
  checkAuthentication(): void {
    if (!this.isAuthenticated()) {
      this.router.navigate(["/login"]);
    }
  }

  getV2CToken(): Promise<string> {
    console.log("GETV2CTOKEN");
    return new Promise(async (resolve) => {
      try {
        console.log("ENTRAMOS A GETV2CTOKEN");
        const oauth = "Basic " + environment.v2cServiceConfig.basicOAuth;
        const header = new HttpHeaders().set("Authorization", oauth);
        const requestOptions = { headers: header };
        let user = JSON.parse(localStorage.getItem("user") || '{}');
        
        if (localStorage.getItem("clearItem") === "si") {
          console.log("Pedimos user de nuevo al refrescar el token");
          user = await this.getUser();
        } else {
          user = JSON.parse(localStorage.getItem("user") || '{}');
        }
        
        // JSON.parse(localStorage.getItem("user") || '{}');
        console.log(user);

        // Verificamos si el token está en `user.stsTokenManager` o en `user.multiFactor.user.stsTokenManager`
        let tokenFirebase: string | undefined;
        
        if (user.stsTokenManager && user.stsTokenManager.accessToken) {
          // Si el token está en la ruta original
          console.log("Ruta original");
          tokenFirebase = user.stsTokenManager.accessToken;
        } else if (user.multiFactor && user.multiFactor.user && user.multiFactor.user.stsTokenManager) {
          // Si el token está en la ruta anidada dentro de multiFactor
          console.log("Ruta anidada cuando caduca token firebase");
          tokenFirebase = user.multiFactor.user.stsTokenManager.accessToken;
        }

        // Si no encontramos el token, lanzamos un error o resolvemos con una cadena vacía
        if (!tokenFirebase) {
          console.log("No se pudo encontrar el token Firebase");
          return resolve("");
        }

        const params = new HttpParams()
          .set("grant_type", "firebase")
          .set("firebase_token_id", tokenFirebase);
        
        const response: any = await firstValueFrom(
          this.http.post(
            environment.v2cServiceConfig.basePath + "/oauth/token",
            params,
            requestOptions
          )
        );

        resolve(response.access_token);

      } catch (error) {
        console.log("ERROR DE AUTENTICACION AL OBTENER TOKEN");
        console.log(error);
        resolve("");
      }
    });
  }

  async getUser() {
    console.log("GET USER");

    try {
        const user = await firstValueFrom(
            this.ngFireAuth.authState.pipe(
                first(),
                timeout(4000),
                retry({
                    count: 3, // Reintenta hasta 3 veces
                    delay: (error, retryCount) => {
                        console.log(`Retry attempt #${retryCount}`);
                        return of(error).pipe(delay(1000 * retryCount)); // Backoff exponencial
                    }
                }),
                catchError(error => {
                    console.error("El metodo GETUSER ha fallado, el internet no funciona bien", error);
                    return of(null); // Retorna null si hay un error
                })
            )
        );

        console.log("EL METODO HA FUNCIONADO VEMOS EL USER", user);

        if (user) {
            // Aquí forzamos la actualización del token, si ha caducado o está a punto de hacerlo.
            const tokenFirebase = await user.getIdToken(true); // Forzar el refresco del token

            console.log("TOKEN FIREBASE ACTUALIZADO", tokenFirebase);
            user["stsTokenManager"] = {
                accessToken: tokenFirebase,
                expirationTime: user["stsTokenManager"]?.expirationTime || Date.now() + 3600 * 1000, // 1 hora por defecto
            };

            localStorage.setItem("user", JSON.stringify(user));
        }

        return user;

        } catch (error) {
            console.error("Error al obtener el usuario:", error);
            return null;
        }
    }
}
