import { HttpInterceptorFn, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable, from, throwError } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  let token: string | null = localStorage.getItem("token"); // Intentar recuperar el token almacenado

  console.log("AUTH INTERCEPTOR");

  if (!req.url.includes("/oauth/token")) {
    if (localStorage.getItem("clearItem") === "si") {
      token = null;
      localStorage.removeItem("token"); // Eliminar el token si clearItem es "si"
    }

    if (token) {
      console.log("TOKEN EN LOCALSTORAGE");

      const authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });

      return next(authReq);
    } else {
      console.log("SOLICITANDO NUEVO TOKEN");

      return from(authService.getV2CToken()).pipe(
        switchMap((newToken) => {
          if (newToken) {
            localStorage.setItem("clearItem", "no");
            localStorage.setItem("token", newToken); // Guardar el nuevo token

            const authReq = req.clone({
              setHeaders: {
                Authorization: `Bearer ${newToken}`,
              },
            });

            return next(authReq);
          }

          return next(req);
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
    }
  } else {
    return next(req);
  }
};
