import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { firstValueFrom, of } from "rxjs";
import { catchError, delay, first, retry, timeout } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/compat/auth";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private ngFireAuth: AngularFireAuth
  ) {}

  // Método para verificar si el usuario está autenticado
  isAuthenticated(): boolean {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user || !user["email"]) {
      return false;
    } else if (user["email"] == "admin@v2c.com" || user["email"] == "developers@v2c.com" || user["email"] == "support@v2c.com" || user["email"] == "accounting@v2c.com"){
      return false;
    } else {
      return true;
    }
  }

  // Método para redirigir al usuario al login si no está autenticado
  checkAuthentication(): void {
    if (!this.isAuthenticated()) {
      this.router.navigate(["/login"]);
    }
  }

  getV2CToken(): Promise<string> {
    console.log("GETV2CTOKEN");
    return new Promise(async (resolve) => {
      try {
        let user = JSON.parse(localStorage.getItem("user"));
        if (localStorage.getItem("refreshUser") === "true" || !user) {
          user = await this.getUser();
        }

        const firebaseToken = user["firebaseToken"];

        if (!firebaseToken) {
          return resolve("");
        }

        const oauth = "Basic " + environment.v2cServiceConfig.basicOAuth;
        const header = new HttpHeaders().set("Authorization", oauth);
        const requestOptions = { headers: header };
        const params = new HttpParams().set("grant_type", "firebase").set("firebase_token_id", firebaseToken);
        
        const response: any = await firstValueFrom(
          this.http.post(
            environment.v2cServiceConfig.basePath + "/oauth/token",
            params,
            requestOptions
          )
        );

        resolve(response.access_token);
      } catch (error) {
        console.log("ERROR DE AUTENTICACION AL OBTENER TOKEN");
        console.log(error);
        resolve("");
      }
    });
  }

  async getUser() {
    try {
      const user = await firstValueFrom(
        this.ngFireAuth.authState.pipe(
          first(),
          timeout(4000),
          retry({
            count: 3, // Reintenta hasta 3 veces
            delay: (error, retryCount) => {
              console.log(`Retry attempt #${retryCount}`);
              return of(error).pipe(delay(1000 * retryCount)); // Backoff exponencial
            }
          }),
          catchError(error => {
            console.error("El metodo GETUSER ha fallado, el internet no funciona bien", error);
            return of(null);
          })
        )
      );

      if (user) {
        const email = user.email;
        const firebaseToken = await user.getIdToken(true);
        const localUser = {
          email: email,
          firebaseToken: firebaseToken
        };

        localStorage.setItem("refreshUser", "false");
        localStorage.setItem("user", JSON.stringify(localUser));
        return localUser;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error al obtener el usuario:", error);
      return null;
    }
  }
}
