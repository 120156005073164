import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FirebaseService } from "../shared/services/authentication/firebase.service";
import { SwalToastService } from './../shared/services/swal-toast.service';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, TranslateModule, NgxSpinnerModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', Validators.required)
  })
  loginFormSubmitted = false;
  constructor(
    private router: Router,
    private translate: TranslateService,
    private firebaseService: FirebaseService,
    private toast: SwalToastService,
    private spinner: NgxSpinnerService
  ) {
    sessionStorage.removeItem('deviceInfo');
    this.showGif();
    this.signOut();
  }

  get lf() {
    return this.loginForm.controls;
  }

  showGif() {
    // Comprobar si el usuario ya ha visto el preloader
    const hasSeenPreloader = sessionStorage.getItem('hasSeenPreloader');
    console.log(hasSeenPreloader);
    
    if (!hasSeenPreloader) {
      // Si no lo ha visto, mostrar el preloader y guardarlo en sessionStorage
      setTimeout(function () {
        var preloader = document.getElementById("preloader");
        console.log(preloader);
        if(preloader != null) {
          preloader.style.display = "none";
        }
      }, 3000); // 3000 milisegundos = 3 segundos

      sessionStorage.setItem('hasSeenPreloader', 'true');
    } else {
      // Si ya lo ha visto, ocultar el preloader inmediatamente
      setTimeout(function () {
        var preloader = document.getElementById("preloader");
        console.log(preloader);
        if(preloader != null) {
          preloader.style.display = "none";
        }
      }, 1);
    }
  }

  showSpinner() {
    this.spinner.show(undefined, {
      type: 'ball-spin-clockwise',
      size: 'medium',
      bdColor: 'rgba(0, 0, 0, 0.8)',
      color: 'var(--color-white)'
    });
  }

  goCrearCuenta() {
    console.log("Crear cuenta");
    this.router.navigate(["/login/register"]);
  }

  onSubmit() {
    console.log("Acceder");
    console.log(this.loginForm);
    console.log(this.loginForm.value);
    if(this.loginForm.valid) {
      console.log("Log in valido");
      this.showSpinner();
      this.firebaseService.signIn(this.loginForm.value.email, this.loginForm.value.password).then(res => {
        if (res.user.emailVerified) {
          this.saveUser(res.user);
          this.spinner.hide();
          this.router.navigate(["/home"]);
          return true;
        } else {
          this.toast.warning(this.translate.instant('ToastCorreoNoVerificado'));
          console.log("EMAIL NO VERIFICADO");
          this.spinner.hide();
          return false;
        }
      }).catch((error) => {
        console.log(error);
        console.log(error.code); // Usar error.code para identificar el tipo de error

        switch (error.code) {
          case "auth/user-not-found":
            // Mostrar error de usuario no encontrado
            console.log("EMAIL INCORRECTO O NO EXISTE");
            this.toast.error(this.translate.instant('ToastCorreoIncorrecto'));
            break;
          case "auth/wrong-password":
            // Mostrar error de contraseña incorrecta
            console.log("CONTRASEÑA INCORRECTA");
            this.toast.error(this.translate.instant('ToastPasswordIncorrecta'));
            break;
          case "auth/network-request-failed":
            // Mostrar error de red o conexión
            console.log("NO TIENES CONEXIÓN");
            this.toast.error(this.translate.instant('ToastErrorConexion'));
            break;
          case "auth/too-many-requests":
            // Mostrar error de muchos intentos fallidos
            console.log("DEMASIADOS INTENTOS FALLIDOS");
            this.toast.error(this.translate.instant('ToastDemasiadosIntentos'));
            break;
          // Agrega más casos según los códigos de error que necesites manejar
          default:
            // Mostrar un error genérico para otros casos
            console.log("OTRO ERROR de Inicio de sesión");
            this.toast.error(this.translate.instant('ToastErrorIniciarSesion'));
            break;
        }
        this.spinner.hide();
      })
    } else {
      console.log("Log in invalido");
      if(this.loginForm.value.email.trim() == "") {
        console.log("Debes introducir un email válido");
        this.toast.warning(this.translate.instant('ToastDebesIntroducirEmailValido'));
      } else if(this.loginForm.value.password.trim() == "") {
        console.log("Debes introducir una contraseña válida");
        this.toast.warning(this.translate.instant('ToastDebesIntroducirPasswordValido'));
      } else {
        console.log("Debes introducir un email válido");
        this.toast.warning(this.translate.instant('ToastDebesIntroducirEmailValido'));
      }
    }
  }

  goForgotPassword() {
    console.log("Olvidado contraseña");
    this.router.navigate(["/login/forgot"]);
  }

  async goLoginApple() {
    console.log("Login Apple");
    const result = await this.firebaseService.signInWithApple();
    if (result.user.emailVerified) {
      this.saveUser(result.user);
      this.router.navigate(["/home"]);
    } else {
      console.log("EMAIL NO VERIFICADO");
      this.toast.warning(this.translate.instant('ToastCorreoNoVerificado'));
    }
  }

  async goLoginGoogle() {
    console.log("Login google");
    const result = await this.firebaseService.signInWithGoogle();
    if (result.user.emailVerified) {
      this.saveUser(result.user);
      this.router.navigate(["/home"]);
    } else {
      console.log("EMAIL NO VERIFICADO");
      this.toast.warning(this.translate.instant('ToastCorreoNoVerificado'));
    }
  }

  saveUser(user: any) {
    const email = user["email"];
    let firebaseToken: any;
    if (user["stsTokenManager"] && user["stsTokenManager"]["accessToken"]) {
      firebaseToken = user["stsTokenManager"]["accessToken"];
      localStorage.setItem("refreshUser", "false");
      localStorage.setItem("refreshToken", "true");
    } else if (user["multiFactor"] && user["multiFactor"]["user"] && user["multiFactor"]["user"]["stsTokenManager"]) {
      firebaseToken = user["multiFactor"]["user"]["stsTokenManager"]["accessToken"];
      localStorage.setItem("refreshUser", "false");
      localStorage.setItem("refreshToken", "true");
    } else {
      firebaseToken = "";
      localStorage.setItem("refreshUser", "true");
    }

    const localUser = {
      email: email,
      firebaseToken: firebaseToken
    };
    localStorage.setItem("user", JSON.stringify(localUser));
  }

  async signOut() {
    console.log("Cerrando sesión...");
    this.firebaseService.signOut();
  }
}
