import { Routes } from "@angular/router";
import { ErrorComponent } from "./error/error.component";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./login/register/register.component";
import { authGuard } from "./shared/services/authentication/auth.guard";
import { ForgotComponent } from "./login/forgot/forgot.component";

export const routes: Routes = [
    {path: "", redirectTo: "/home", pathMatch: "full"},
    {path: "login", title: "V2C Cloud", component: LoginComponent },
    {path: "login/register", title: "V2C Cloud", component: RegisterComponent },
    {path: "login/forgot", title: "V2C Cloud", component: ForgotComponent },
    {path: "home", canActivateChild: [authGuard], loadChildren: () => import("./home/home.routes").then(m => m.HOME_ROUTES)},
    {path: "**", title: "Error", component: ErrorComponent}
];
