<div class="login-2">
    <div class="fondoPrincipal"></div>
    <form class="main-container">
      <div class="main-container-child"></div>
      <div class="login-header">
        <img class="logo-white-1-icon" alt="" src="../../assets/img/v2c-logo-w.png" />
        <div class="login-title-container">
          <div class="acceder-a-mi">{{'LabelRecuperarContrasenya' | translate}}</div>
        </div>
        <div class="login-subtitle-container">
            <p class="label-info">{{'LabelRecuperarContrasenyaInfo' | translate}}</p>
          </div>
        <form [formGroup]="recoverForm" class="login-form" (ngSubmit)="recoverPassword()">
          <div class="email">
            <div class="email-child"></div>
            <input class="email1" formControlName="email" placeholder="{{'LabelEmail' | translate}}" type="text" />
          </div>
        </form>
        <div class="account-actions">
            <div class="register-login" style="margin-left: -20px;">
                <button class="registro-boton" type="button" (click)="goLogin()">
                <p class="crear-cuenta" id="crearCuentaText">
                  {{'LabelVolverInicioSesion' | translate}}
                </p>
                </button>
            </div>
            <div class="register-login" style="margin-left: 20px;">
                <button class="acceder-boton" type="button" (click)="recoverPassword()">
                    <p class="acceder">
                      {{'LabelRecuperar' | translate}}
                    </p>
                </button>
            </div>
        </div>
      </div>
    </form>
  </div>

