<ngx-spinner></ngx-spinner>
<div id="preloader">
    <img src="../../assets/img/initial-gif.gif" alt="Loading..." />
  </div>
  <div class="login-2">
    <div class="fondoPrincipal"></div>

    <form class="main-container">
      <div class="main-container-child"></div>
      

      <div class="login-header">
        <img class="logo-white-1-icon" alt="" src="../../assets/img/v2c-logo-w.png" />
        <div class="login-title-container">
          <div class="acceder-a-mi">{{'LabelAccederCuenta' | translate}}</div>
        </div>
        <form [formGroup]="loginForm" class="login-form" (ngSubmit)="onSubmit()">
            <input type="text" class="email" formControlName="email" placeholder="{{'LabelEmail' | translate}}"
                [ngClass]="{ 'is-invalid': loginFormSubmitted && lf.email.invalid, 'is-valid': loginFormSubmitted && !lf.email.invalid }" />
            <input class="email" (keyup.enter)="onSubmit()" formControlName="password" placeholder="{{'LabelContrasenya' | translate}}" type="password" />
          <div (click)="goForgotPassword()" class="forgotten-password">{{'LabelOlvidadoContrasenya' | translate}}</div>
        </form>
        <div class="account-btns">
          <div (click)="goCrearCuenta()" class="create-btn">
            {{'LabelCrearCuentaMinus' | translate}}
          </div>
          <div (click)="onSubmit()" class="access-btn">
            {{'LabelAcceder' | translate}}
          </div>
        </div>
      </div>
      <div class="social-login">
        <div class="social-buttons-container">
          <div (click)="goLoginApple()" class="social-icons-wrapper1">
            <div class="social-icons-wrapper-child"></div>
            <img class="apple-os-system-apple-icon" loading="lazy" alt="" src="../../assets/img/login-icono-apple.svg"/>
          </div>
          <button (click)="goLoginGoogle()" type="button" class="social-icons-wrapper1">
            <div class="social-icons-wrapper-item"></div>
            <img class="google-media-google-social-icon" alt="" src="../../assets/img/login-icono-google.svg"/>
          </button>
        </div>
      </div>
    </form>
  </div>
