<ngx-spinner></ngx-spinner>
<div id="preloader">
  <img src="../../assets/img/initial-gif.gif" alt="Loading..."/>
</div>
<div class="content">
  <div class="background-img"></div>

  <div class="login-section">
    <div class="main-login">
      <img class="logo" alt="" src="../../assets/img/v2c-logo-w.png"/>
      <div class="login-title">
        {{'LabelAccederCuenta' | translate}}
      </div>
      <form [formGroup]="loginForm" class="login-form" (ngSubmit)="onSubmit()">
        <input type="text" class="form-input" formControlName="email" placeholder="{{'LabelEmail' | translate}}"/>
        <input type="password" class="form-input" formControlName="password" placeholder="{{'LabelContrasenya' | translate}}" (keyup.enter)="onSubmit()"/>
        <div (click)="goForgotPassword()" class="forgot-password">{{'LabelOlvidadoContrasenya' | translate}}</div>
      </form>
      <div class="account-btns">
        <div (click)="goCrearCuenta()" class="create-btn">
          {{'LabelCrearCuentaMinus' | translate}}
        </div>
        <div (click)="onSubmit()" class="access-btn">
          {{'LabelAcceder' | translate}}
        </div>
      </div>
    </div>
    <div class="social-login">
      <div (click)="goLoginApple()" class="social-btn" title="{{'LabelIniciarApple' | translate}}">
        <img class="apple-icon" alt="Apple" src="../../assets/img/login-icono-apple.svg"/>
      </div>
      <div (click)="goLoginGoogle()" class="social-btn" title="{{'LabelIniciarGoogle' | translate}}">
        <img class="google-icon" alt="Google" src="../../assets/img/login-icono-google.svg"/>
      </div>
    </div>
  </div>
</div>
