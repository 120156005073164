<div class="content">
  <div class="background-img"></div>

  <div class="forgot-section">
    <img class="logo" alt="" src="../../assets/img/v2c-logo-w.png" />
    <div class="forgot-title">
      {{'LabelRecuperarContrasenya' | translate}}
    </div>
    <div class="forgot-subtitle">
      {{'LabelRecuperarContrasenyaInfo' | translate}}
    </div>
    <form [formGroup]="recoverForm" class="forgot-form" (ngSubmit)="recoverPassword()">
      <input class="form-input" formControlName="email" placeholder="{{'LabelEmail' | translate}}" type="text" />
    </form>
    <div class="account-btns">
      <div (click)="goLogin()" class="go-back-btn">
        {{'LabelVolverInicioSesion' | translate}}
      </div>
      <div (click)="recoverPassword()" class="recover-btn">
        {{'LabelRecuperar' | translate}}
      </div>
    </div>
  </div>
</div>

