import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { PreloadAllModules, provideRouter, withPreloading } from '@angular/router';

import { routes } from './app.routes';
import { provideTranslation } from './app.translation';
import { provideClientHydration } from '@angular/platform-browser';
import { HttpClientModule, withInterceptors, withFetch } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { provideToastr } from 'ngx-toastr';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { initializeAppCheck, ReCaptchaEnterpriseProvider, provideAppCheck } from '@angular/fire/app-check';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { provideHttpClient } from '@angular/common/http';
import { authInterceptor } from './shared/services/auth.interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';

const firebaseConfig = {
  apiKey: "AIzaSyBklmqF9xBIuQEdW1BQ6da0uZSbfnXtH1w",
  authDomain: "v2c-app.firebaseapp.com",
  databaseURL: "https://v2c-app.firebaseio.com",
  projectId: "v2c-app",
  storageBucket: "v2c-app.appspot.com",
  messagingSenderId: "600121721573",
  appId: "1:600121721573:web:aab59199f9337daa354a0c",
  measurementId: "G-HJ952XH1Q4"
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withPreloading(PreloadAllModules)),
    provideHttpClient(withInterceptors([authInterceptor])),
    provideHttpClient(withFetch()),
    provideClientHydration(),
    provideToastr(),
    importProvidersFrom([
      HttpClientModule,
      TranslateModule.forRoot(provideTranslation()),
      AngularFireModule.initializeApp(firebaseConfig),
      AngularFirestoreModule
    ]), 
    provideAnimationsAsync(), 
    provideFirebaseApp(() => initializeApp(firebaseConfig)), 
    provideAuth(() => getAuth()), 
    provideAnalytics(() => getAnalytics()), 
    ScreenTrackingService, 
    UserTrackingService,
    provideFirestore(() => getFirestore()), 
    provideDatabase(() => getDatabase())
  ]
};
