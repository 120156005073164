import { HttpInterceptorFn, HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from './auth.service';
import { from, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.url.includes("/oauth/token")) {
    return next(req);
  }

  let token = localStorage.getItem("token");
  if (!token || localStorage.getItem("refreshUser") !== "false" || localStorage.getItem("refreshToken") !== "false") {
    localStorage.removeItem("token");
    const authService = inject(AuthService);
    return from(authService.getV2CToken()).pipe(
      switchMap((newToken) => {
        if (newToken) {
          localStorage.setItem("refreshToken", "false");
          localStorage.setItem("token", newToken);

          const authReq = req.clone({
            setHeaders: {
              Authorization: `Bearer ${newToken}`,
            },
          });

          return next(authReq);
        }

        return next(req);
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  const authReq = req.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });

  return next(authReq);
};
