<div class="content">
    <div class="banner">
        <img src="../../assets/img/error-cable.svg" class="left-cable">
        <div class="web-logo">
            <img src="../../../assets/img/v2c-logo-b.png" alt="V2C">
            Cloud
        </div>
        <img src="../../assets/img/error-cable.svg" class="right-cable">
    </div>
    <div class="title">
        {{'LabelError404' | translate}}
    </div>
    <div class="subtitle">
        {{'LabelError404Info' | translate}}
    </div>
    <div class="link-list">
        <div class="list-header">
            {{'LabelPaginasInteresar' | translate}}:
        </div>
        <a href="login">
            {{'LabelCloudInicio' | translate}}
        </a>
        <a [href]="'https://v2charge.com/' + langLink">
            {{'LabelV2CWeb' | translate}}
        </a>
        <a [href]="'https://v2charge.com/' + langLink + 'blog/'">
            {{'LabelUltimasNoticias' | translate}}
        </a>
    </div>
</div>