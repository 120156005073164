import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss'
})
export class ErrorComponent {
  langLink = '';

  constructor() {
    const lang = localStorage.getItem('Idioma');

    switch (lang) {
      case 'es': {
        this.langLink = lang + '/';
        break;
      }
      case 'de': {
        this.langLink = lang + '/';
        break;
      }
      case 'fr': {
        this.langLink = lang + '/';
        break;
      }
      case 'it': {
        this.langLink = lang + '/';
        break;
      }
      case 'pt': {
        this.langLink = 'pt-pt/';
        break;
      }
      default: {
        this.langLink = '';
      }
    }
  }
}
