import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';
import { RouterOutlet, RouterLink, RouterLinkActive, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from "../../shared/services/authentication.service";
import { SwalToastService } from '../../shared/services/swal-toast.service';
import { PopupService } from '../../shared/services/popup.service';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, TranslateModule, NgxSpinnerModule],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})

export class RegisterComponent {

  registerForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    confirmPassword: new FormControl('', Validators.required),
    acceptTerms: new FormControl(false, Validators.requiredTrue),  // Checkbox de términos obligatorio
    acceptMarketing: new FormControl(false)  // Checkbox opcional
  })
  constructor(
    private router: Router,
    private translate: TranslateService,
    public authServices: AuthenticationService,
    private toast: SwalToastService,
    private popupService: PopupService,
    private spinner: NgxSpinnerService
  ) {

  }

  showSpinner() {
    this.spinner.show(undefined, {
      type: 'ball-spin-clockwise',
      size: 'medium',
      bdColor: 'rgba(0, 0, 0, 0.8)',
      color: 'var(--color-white)'
    });
  }

  goAcceder() {
    console.log("Crear cuenta");
    console.log(this.registerForm);
    if (this.registerForm.valid) {
      console.log("Form submitted", this.registerForm.value);
      if (this.registerForm.value.password != this.registerForm.value.confirmPassword) {
        this.toast.error(this.translate.instant('ToastDebesConfirmarContrasenya'));
        return;
      }
      this.showSpinner();
      // Lógica para crear la cuenta
      this.authServices
      .RegisterUser(
        this.registerForm.value.email,
        this.registerForm.value.password
      )
      .then((res) => {
        // Do something here
        console.log(res);
        this.authServices.SendVerificationMail();
        const popupData = {
          type: 1,
          message: this.translate.instant("AlertVerificaCorreoElectronico")
        };
        this.popupService.openPopup(popupData);
        this.spinner.hide();
        this.router.navigate(["/login"]);
      })
      .catch((error) => {
        console.log(error);
        console.log(error.code); // Usamos el código de error en lugar del mensaje

        // Manejo de errores basado en error.code
        switch (error.code) {
          case "auth/weak-password":
            this.toast.error(this.translate.instant('ToastErrorContrasenyaCorta'));
            break;
          case "auth/network-request-failed":
            this.toast.error(this.translate.instant('ToastErrorConexion'));
            break;
          case "auth/invalid-email":
            this.toast.error(this.translate.instant('ToastErrorFormatoIncorrecto'));
            break;
          case "auth/email-already-in-use":
            this.toast.error(this.translate.instant('ToastErrorCorreoRegistrado'));
            break;
          default:
            this.toast.error(this.translate.instant('ToastErrorAlRegistrar'));
            break;
        }
        this.spinner.hide();
      });
    } else {
      // Lógica cuando el formulario no es válido
      console.log(this.registerForm);
      if (this.registerForm.status == "INVALID") {
        // alert("Debes aceptar la política de términos y condiciones para continuar.");
        console.log("Registro inválido");
        console.log(this.registerForm);
        if(this.registerForm.value.email.trim() == "") {
          this.toast.warning(this.translate.instant('ToastDebesIntroducirEmailValido'));
        } else if(this.registerForm.controls.password.status == "INVALID") {
          if(this.registerForm.value.password.trim() == ""){
            this.toast.warning(this.translate.instant('ToastDebesIntroducirPasswordValido'));
          } else if(this.registerForm.controls.password.errors["minLength"]["actualLength"] < 6) {
            this.toast.error(this.translate.instant('ToastErrorContrasenyaCorta'));
          }
        } else if(this.registerForm.controls.acceptTerms.status == "INVALID") {
          this.toast.error(this.translate.instant('ToastDebesAceptarCondiciones'));
        } else if(this.registerForm.controls.confirmPassword.status == "INVALID"){
          this.toast.error(this.translate.instant('ToastDebesConfirmarContrasenya'));
        } else if(this.registerForm.controls.email.status == "INVALID") {
          this.toast.error(this.translate.instant('ToastErrorFormatoIncorrecto'));
        } else {
          this.toast.error(this.translate.instant('ToastErrorAlRegistrar'));
        }
        return;
      }
    }
  }

  goLogin() {
    console.log("Volver a login");
    this.router.navigate(["/login"]);
  }
}
