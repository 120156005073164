<router-outlet>
    <dialog type="modal" id="dialogNewWeb" class="drawer2" #dialogNewWeb>
        <div class="container-title-modal">
            <p class="title-modal">{{'LabelTitleModalNewWeb' | translate}}</p>
            <img class="img-title" src="./assets/img/icons/shuttle-b.png">
        </div>
        <div class="container-text-modal">
            <p class="subtitle-modal-bold">{{'LabelSubtitleModalNewWeb1' | translate}}</p>
            <p class="subtitle-modal">{{'LabelSubtitleModalNewWeb2' | translate}}</p>
            <p class="subtitle-modal">{{'LabelSubtitleModalNewWeb3' | translate}}</p>
            <p class="subtitle-modal">{{'LabelSubtitleModalNewWeb4' | translate}}</p>
        </div>
        <div class="container-btn-modal">
            <div class="btn-ir-web" (click)="closeModalNewWeb()">
                <p class="text-btn-modal">{{'LabelEntendido' | translate}}</p>
            </div>
        </div>
    </dialog>   
</router-outlet>