<ngx-spinner></ngx-spinner>
<div class="content">
  <div class="background-img"></div>

  <div class="register-section">
    <img class="logo" alt="" src="../../assets/img/v2c-logo-w.png"/>
    <div class="register-title">
      {{'LabelCrearCuenta' | translate}}
    </div>
    <form [formGroup]="registerForm" class="register-form" (ngSubmit)="goAcceder()">
      <input class="form-input" formControlName="email" placeholder="{{'LabelEmail' | translate}}" type="text" />
      <input class="form-input" formControlName="password" placeholder="{{'LabelContraseña' | translate}}" type="text" />
      <input class="form-input" formControlName="confirmPassword" placeholder="{{'LabelConfirmarContraseña' | translate}}" type="text" />
      <div class="form-checkbox">
        <input type="checkbox" class="circular-checkbox" formControlName="acceptTerms"/>
        <a href="https://v2charge.com/privacy-policy/" target="_blank">{{" " + ('LabelHeLeidoPolitica' | translate)}}</a>
      </div>
      <div class="form-checkbox">
        <input type="checkbox" class="circular-checkbox" formControlName="acceptMarketing"/>
        {{" " + ('LabelAceptoTratamientoDatos' | translate)}}
      </div>
    </form>
    <div class="account-btns">
      <div (click)="goLogin()" class="already-have-btn">
        {{'LabelTengoCuenta' | translate}}
      </div>
      <div (click)="goAcceder()" class="create-btn">
        {{'LabelCrear' | translate}}
      </div>
    </div>
  </div>
</div>
