import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterOutlet, RouterLink, RouterLinkActive, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from "../../shared/services/authentication.service";
import { MatDialogModule } from '@angular/material/dialog';
import { SwalToastService } from '../../shared/services/swal-toast.service';

@Component({
  selector: 'app-forgot',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, MatDialogModule, TranslateModule],
  templateUrl: './forgot.component.html',
  styleUrl: './forgot.component.scss'
})
export class ForgotComponent {
  recoverForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  })
  showPopup: any;
  constructor(
    private router: Router,
    private translate: TranslateService,
    public authServices: AuthenticationService,
    private toast: SwalToastService,
  ) {

    }

    recoverPassword() {
      console.log("Cambiar contraseña");
      console.log(this.recoverForm);
      if (this.recoverForm.invalid) {
        this.toast.warning(this.translate.instant('ToastDebesIntroducirEmailValido'));
        return;
      }
      this.authServices.PasswordRecoverLogin(this.recoverForm.value.email);
    }

    goLogin() {
      console.log("Volver a login");
      this.router.navigate(["/login"]);
    }
}
